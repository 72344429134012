import {fetchJson} from './http';

export interface Location {
    id: number,
    name: string,
}

export interface Project {
    id: number,
    name: string,
    description: string,
    location: Location,
    status: number,
    selected?: boolean,
}

export default class LegacyApi {
    async getProjects(organizationId: number): Promise<Project[]> {
        let projects: Project[] = [];
        let limit = 1000;
        let offset = 1;
        let resultCount = 0;
        do {
            let response = await fetchJson(`${process.env.REACT_APP_AUTH_BASE_URL}/projects?limit=${limit}&offset=${offset}`, {method: 'GET'});
            resultCount = response.result.projects.length;
            projects.push(...response.result.projects);
            offset += resultCount;
        } while (resultCount === limit)

        return projects;
    }
}
